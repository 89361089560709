import React from "react";
import "./NotificationRow.css";

function NotificationRow({
    notification_message_count,
    notification_message_modified,
    adm_name,
    notification_date,
    notification_no_email,
}) {
    return (
        <tr>
            <td className="P15">{notification_message_count}</td>
            <td className="P15">{notification_message_modified}</td>
            <td className="P15">{adm_name}</td>
            <td className="P15">{notification_date}</td>
            <td className="P15">{notification_no_email}</td>
        </tr>
    );
}

export default NotificationRow;