import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import "./RequestformConfigSetup.css";
import { toast } from 'react-toastify';

function RequestformConfigSetup(props) {
    const { id } = props;

    const [value, setValue] = useState("");
    const [key, setKey] = useState(id);

    useEffect(() => {
        const fetchRequestformConfig = async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getRequestformConfigByKey?key=" + id);
                const obj = resp.data;
                if (obj.status && obj.results) {
                    setValue(obj.results.value);
                }
            } catch (error) {
                console.error('Error fetching text mail:', error);
            }
        };

        if (id) {
            fetchRequestformConfig();
        }
    }, [id]);

    const updateRequestformConfig = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_updateRequestformConfig", {
                key: key,
                value: value,
            });
            const obj = resp.data;

            if (obj.status) {
                toast.success('Requestform Config updated successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Failed to update Requestform Config', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error updating requestform Config:', error);
            toast.error('Failed to update the mail', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const insertRequestformConfig = async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_insertRequestformConfig", {
                key: key,
                value: value,
            });
            const obj = resp.data;

            if (obj.status) {
                toast.success('Requestform Config insert successfully', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Failed to insert Requestform Config', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error insert requestform Config:', error);
            toast.error('Failed to insert the mail', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className={`RequestformConfigSetup GridCol1-13 P25 Width100 BoxSizeBorder ${props.childClass}`}>
            <div className={"MB20"}>
                <div>
                    <div className="FWBold">Key</div>
                    <input
                        type="text"
                        name="requestformConfigKey"
                        id="requestformConfigKey"
                        className="ActionInput P5"
                        disabled={id !== ""}
                        value={key}
                        onChange={(evt) => setKey(evt.target.value)}
                    />
                </div>
                <div>
                    <div className="FWBold">Value</div>
                    <input
                        type="text"
                        name="requestformConfigValue"
                        id="requestformConfigValue"
                        className="ActionInput P5"
                        value={value}
                        onChange={(evt) => setValue(evt.target.value)}
                    />
                </div>
            </div>


            <div className="popup-footer Gap10 FlexRow JustifyBetween">
                <button
                    type="button"
                    onClick={id !== "" ? updateRequestformConfig : insertRequestformConfig}
                    className="ActionButton BGreen"
                >
                    Validate
                </button>
                <button
                    className="ActionButton BBlack TWhite"
                    onClick={props.togglePopup}
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default RequestformConfigSetup;