import LateralNavComponent from "../../Components/LateralNavComponent/LateralNavComponent";
import './EditSiteOptions.css';
import { useState, useEffect } from "react";
import HeadLine from "../../Components/HeadLine/HeadLine";
import { ToastContainer } from "react-toastify";
import SiteFormats from "../../Components/SiteFormats/SiteFormats";
import { useParams } from "react-router-dom";
import SiteHeader from "../../Components/SiteHeader/SiteHeader";
import SiteTopGeo from "../../Components/SiteTopGeo/SiteTopGeo";
import SiteLabel from "../../Components/SiteLabel/SiteLabel";
import SiteTags from "../../Components/SiteTags/SiteTags";
import SitePartnersState from "../../Components/SitePartnersState/SitePartnersState";
import SiteOptions from "../../Components/SiteOptions/SiteOptions";
import AuthComponent from "../../Components/AuthComponent/AuthComponent"
import IVTAnalysis from "../../Components/IVTAnalysis/IVTAnalysis";

function EditSiteOptions() {
  const pageTab = "site_options";
  let { id } = useParams();

  const [siteID, setSiteID] = useState(false)

  useEffect(() => {
    setSiteID(id);
  }, [id]);

  return (
    <div className="GridTemplate12">
      <AuthComponent />

      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LateralNavComponent pageTab={pageTab} />
      <HeadLine childClass="Headline GridCol1-13" title="Site's options" />
      <SiteHeader siteID={siteID} />
      <SiteOptions siteID={siteID} />
      <IVTAnalysis siteID={siteID} />
      <SiteTopGeo siteID={siteID} />
      <SiteLabel siteID={siteID} />
      <SitePartnersState siteID={siteID} />
      <SiteTags siteID={siteID} />
      <SiteFormats siteID={siteID} />
    </div>
  );
}

export default EditSiteOptions;
