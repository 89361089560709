import './RequestformConfigRow.css';
import { useState } from 'react';
import RequestformConfigSetup from '../RequestformConfigSetup/RequestformConfigSetup';

function RequestformConfigRow(props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <>
            <tr className="RequestformConfigRow">
                <td className='P5'>{props.row.key}</td>
                <td className='P5'>{props.row.value}</td>
                <td className='P5 FlexRow JustifyCenter Gap10'>
                    <button
                        className="BOrange TWhite ActionButton ML5"
                        onClick={togglePopup}
                    >
                        <i className="fa-solid fa-pen"></i> Edit
                    </button>
                    <button
                        className="BRed TWhite ActionButton ML5"
                        onClick={togglePopup}
                    >
                        <i className="fa-solid fa-trash"></i> Delete
                    </button>
                </td>
            </tr>

            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                        <RequestformConfigSetup
                                id={props.row.key}
                                onSuccess={togglePopup}
                                togglePopup={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default RequestformConfigRow;