import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import "./BusinessFinderList.css";
import BusinessFinderRow from "./BusinessFinderRow/BusinessFinderRow";
import BusinessFinderSetup from "./BusinessFinderSetup/BusinessFinderSetup";

function BusinessFinderList(props) {
    const [businessFinderList, setBusinessFinderList] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentBfId, setCurrentBfId] = useState(0);

    const togglePopup = (id = 0) => {
        setCurrentBfId(id);
        setIsPopupOpen(!isPopupOpen);
    };

    const fetchData = async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_businessFinderList");
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setBusinessFinderList(obj.results);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSuccess = (updatedItem) => {
        if (currentBfId !== 0) {
            setBusinessFinderList(prevList => 
                prevList.map(item => 
                    item.bf_id === currentBfId 
                    ? {
                        ...item,
                        user_firstname: updatedItem.user_firstname,
                        user_lastname: updatedItem.user_lastname,
                        user_mail: updatedItem.user_mail,
                        user_currency: updatedItem.user_currency,
                        ib_revshare: updatedItem.ib_revshare,
                        bf_start_date: updatedItem.bf_start_date,
                        bf_end_date: updatedItem.bf_end_date,
                        bf_nb_month: updatedItem.bf_nb_month
                    }
                    : item
                )
            );
        } else {
            fetchData();
        }
        togglePopup();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className={"BusinessFinderList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
                <div className="TRight MB20">
                    <button className="BGreen TWhite ActionButton ML5 NoUnderline" onClick={() => togglePopup(0)}>
                        <i className="fa-solid fa-plus"></i> Add a Business Finder
                    </button>
                </div>
                <table className='Width100'>
                    <thead>
                        <tr className="BRed TWhite">
                            <th className='P5'>User ID</th>
                            <th className='P5'>Lastname</th>
                            <th className='P5'>Firstname</th>
                            <th className='P5'>Email</th>
                            <th className='P5'>Currency</th>
                            <th className='P5'>Revshare</th>
                            <th className='P5'>Contract begin date</th>
                            <th className='P5'>Contract end date</th>
                            <th className='P5'>Contract Duration</th>
                            <th className='P5'>Status</th>
                            <th className='P5'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {businessFinderList.map((row, key) => {
                            return <BusinessFinderRow
                                key={key}
                                userId={row.user_id}
                                userFirstname={row.user_firstname}
                                userLastname={row.user_lastname}
                                userMail={row.user_mail}
                                userCurrency={row.user_currency}
                                ibRevshare={row.ib_revshare}
                                bfStartDate={row.bf_start_date}
                                bfEndDate={row.bf_end_date}
                                bfNbMonth={row.bf_nb_month}
                                bfStatus={row.bf_status}
                                bfId={row.bf_id}
                                onEdit={() => togglePopup(row.bf_id)}
                                onUpdate={(updatedData) => {
                                    setBusinessFinderList(prevList => 
                                        prevList.map(item => 
                                            item.bf_id === row.bf_id 
                                            ? { ...item, ...updatedData } 
                                            : item
                                        )
                                    );
                                }}
                                fetchData={fetchData}
                            />
                        })}
                    </tbody>
                </table>
            </div>
            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                            <BusinessFinderSetup
                                bfId={currentBfId}
                                onSuccess={handleSuccess}
                                togglePopup={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BusinessFinderList;