import "./IVTAnalysis.css"
import { useEffect, useState } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from '../../Constant';
import { Bar } from "react-chartjs-2";

function IVTAnalysis(props) {

    const [chartData, setChartData] = useState(false);

    useEffect(() => {
        const loadIVTStatistics = async () => {
            try {
                let payload = {
                    params: {
                        q: props.siteID,
                    }
                };
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_user/bo_getIVTStatistics", payload);
                const obj = await resp.data;


                function generateDateRange(startDate, endDate) {
                    let dates = [];
                    let currentDate = new Date(startDate);
                
                    while (currentDate <= endDate) {
                        dates.push(currentDate.toISOString().slice(0, 10));
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                
                    return dates;
                }
                let endDate = new Date();
                endDate.setDate(endDate.getDate() - 1);
                
                let startDate = new Date();
                startDate.setDate(startDate.getDate() - 7);
                let dateRange = generateDateRange(startDate, endDate);

                function generateTypeData(apiData, labels) {
                    let result = {};
                
                    let types = [0, 1, 2, 3];
                    types.forEach(type => {
                        result[type] = new Array(labels.length).fill(0);
                    });
                
                    apiData.forEach(entry => {
                        let type = entry.type;
                        let date = entry.date;
                        let percentage = parseFloat(entry.percentage);
                
                        let dateIndex = labels.indexOf(date);
                        
                        if (dateIndex !== -1) {
                            result[type][dateIndex] = percentage;
                        }
                    });
                
                    return result;
                }

                if (obj.status && obj.results && obj.results.length > 0) {

                    let result = generateTypeData(obj.results, dateRange);
                
                    console.log(result);
                    

                    const data = {
                        labels: dateRange,
                        datasets: [
                            {
                            label: '% Type 0',
                            data: result[0],
                            backgroundColor: "#3498db",
                            },
                            {
                            label: '% Type 1',
                            data: result[1],
                            backgroundColor: "#2ecc71",
                            },
                            {
                            label: '% Type 2',
                            data: result[2],
                            backgroundColor: "#f1c40f",
                            },
                            {
                            label: '% Type 3',
                            data: result[3],
                            backgroundColor: "#e74c3c",
                            },
                        ]
                    };

                    setChartData(data);
                    
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadIVTStatistics();
    }, [props.siteID]);


    return (
        <div className={"SiteOptionsContainer GridCol1-13 ShadowContainer P25 BWhite Width100" + props.childClass}>
            <h2 className="MT0">IVT Analysis</h2>
            <div className="FlexRow">
                <div className="Width100 ">
                    {chartData ? <Bar data={chartData} height={50}
                        options={{
                            plugins: {
                                title: {
                                display: true,
                                text: 'IVT Graph'
                                },
                            },
                            responsive: true,
                            scales: {
                                x: {
                                stacked: true,
                                },
                                y: {
                                stacked: true
                                }
                            }
                    }} /> : <p>No ivt analysis performed in the last 7 days</p> }
                </div>
            </div>
        </div>
    );
}

export default IVTAnalysis;
