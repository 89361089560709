import './AdReportListRow.css';
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { ROOT_DOMAIN_API } from '../../../Constant';
import { Link } from "react-router-dom";

function AdReportListRow(props) {
    const [popupContent, setPopupContent] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isProcessed, setProcessed] = useState(props.reportState === '1');
    const [isDeletedAdReport, setDeletedAdReport] = useState(false);

    useEffect(() => {
        setProcessed(props.reportState === '1');
    }, [props.reportState]);

    const togglePopup = (content = '') => {
        setPopupContent(content);
        setIsPopupOpen(!isPopupOpen);
    };

    const handleCopyClick = (contentToCopy) => {
        navigator.clipboard.writeText(contentToCopy)
            .then(() => {
                toast.success("Copied");
            })
            .catch(err => {
                console.error(err);
            });
    };

    const processReport = (ad_report_id, state) => {
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/updateAdReportState',
                { ad_report_id: parseInt(ad_report_id), ad_report_state: state });
            const obj = await resp.data;
            if (obj.status) {
                setProcessed(true);
                toast.success('Processed', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })();
    }

    const deleteAdReport = (adReportId) => {
        (async () => {
            const resp = await axios.delete(ROOT_DOMAIN_API + '/manage/v1/bo_moderator/adReportList',
                { params: { ad_report_id: parseInt(adReportId) } });
            const obj = await resp.data;
            if (obj.status) {
                setDeletedAdReport(true);

                toast.success('Ad Report deleted', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })();
    }

    const openJSFiddle = (htmlContent) => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://jsfiddle.net/api/post/library/pure/';
        form.target = '_blank';

        const htmlField = document.createElement('input');
        htmlField.type = 'hidden';
        htmlField.name = 'html';
        htmlField.value = htmlContent;
        form.appendChild(htmlField);

        document.body.appendChild(form);
        form.submit();

        document.body.removeChild(form);
    }

    return (
        <tr className={"VAlignTop " + (isDeletedAdReport ? 'Hide' : '')}>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow WrapContent Gap10'>
                        <div className="BorderRad3 BBlue P5 FlexRow AlignCenter JustifyCenter WidthFitContent WidthPx75 TWhite Height25px">
                            <i className={"fa-solid fa-person"}></i>
                            <p className='M0 LightText ML5'>{props.userId}</p>
                        </div>
                        <Link className="BOrange TWhite ActionButton" to={"/options/" + props.siteId}><i className="fa-solid fa-gear"></i></Link>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow'>
                        <a target="_blank" rel="noreferrer" href={props.siteUrl} className="ActionLink">{props.siteUrl}</a>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow WrapContent Gap10'>
                        <div className="BorderRad3 BOrange P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <i className={"fa-solid fa-tag"}></i>
                            <p className='M0 LightText ML5'>{props.formatId}</p>
                        </div>
                        <div className="BorderRad3 BPixelatedGrass P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <i className={"fa-solid fa-dollar-sign"}></i>
                            <p className='M0 LightText ML5'>{props.reportBidder}</p>
                        </div>
                        <div className="BorderRad3 BBlue P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <i className={"fa-solid fa-calendar-days"}></i>
                            <p className='M0 LightText ML5'>{props.reportDate}</p>
                        </div>
                    </div>
                </div>

            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div>
                        <p className='FWBold TBlack'>Reason</p>
                        <p className='M0 LightText TBlack'>{props.reportReason}</p>
                    </div>
                    {props.reportMessage ?
                        <div>
                            <p className='FWBold TBlack'>Message</p>
                            <p className='M0 LightText TBlack'>{props.reportMessage}</p>
                        </div>
                        : ''}
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow WrapContent Gap10'>
                        <div className="BorderRad3 BSoftPink P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <p className='M0 LightText ML5 OverflowEllispsis MaxWidthPx300'><span className='FWBold'>AdId :</span> {props.reportAdId} <i className="fa-solid fa-clipboard ActionButton BPink ML5" onClick={() => handleCopyClick(props.reportAdId)}></i></p>
                        </div>
                        <div className="BorderRad3 BSoftPink P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                            <p className='M0 LightText ML5 OverflowEllispsis MaxWidthPx300' ><span className='FWBold'>CreativeId :</span> {props.reportCreativeId} <i className="fa-solid fa-clipboard ActionButton BPink ML5" onClick={() => handleCopyClick(props.reportCreativeId)}></i></p>
                        </div>
                    </div>
                </div>
            </td>
            <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow WrapContent Gap10 MB10'>
                        <button className='BOrange TWhite ActionButton' onClick={() => togglePopup(props.reportAd)}><i className="fa-solid fa-image"></i> See creative ad</button>
                        <button className='BOrange TWhite ActionButton' onClick={() => openJSFiddle(atob(props.reportAd))}><i className="fa-solid fa-link"></i> Open in JSFiddle</button>
                    </div>
                    <div className='FlexRow WrapContent Gap10'>
                        <button className='BPurple TWhite ActionButton' onClick={() => togglePopup(props.iframeContent)}><i className="fa-solid fa-images"></i> See iframe content</button>
                        <button className='BPurple TWhite ActionButton' onClick={() => openJSFiddle(atob(props.iframeContent))}><i className="fa-solid fa-link"></i> Open in JSFiddle</button>
                    </div>
                    {isPopupOpen && (
                        <div>
                            <div className='overlay'></div>
                            <div className="popup">
                                <textarea className='FullWidth Height300px BoxSizeBorder P10 BorderRad10 AdReportTextArea'>
                                    {atob(popupContent)}
                                </textarea>
                                <div className='FlexRow JustifyBetween MT10'>
                                    <button className='ActionButton BBlue TWhite MR10' onClick={() => handleCopyClick(atob(popupContent))}><i className="fa-solid fa-clipboard" ></i> Copy</button>
                                    <button className='ActionButton BBlack TWhite' onClick={() => togglePopup()}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </td>
            {props.showNonProcessed && <td className='P15'>
                <div className='FlexCol'>
                    <div className='FlexRow'>
                        {isProcessed ?
                            <div className='FlexRow WrapContent Gap10'>
                                <div className="BorderRad3 BPixelatedGrass P5 FlexRow AlignCenter JustifyCenter WidthFitContent TWhite Height25px">
                                    <i className="fa-solid fa-circle-check"></i>
                                    <p className='M0 LightText ML5'>Processed</p>
                                </div>
                                <button className='ActionButton BRed TWhite' onClick={() => deleteAdReport(props.adReportId)}><i className="fa-solid fa-trash"></i> Delete Ad Report</button>
                            </div>
                            :
                            <button className='ActionButton BOrange TWhite' onClick={() => processReport(props.adReportId, 1)}><i className="fa-solid fa-stamp"></i> Process</button>
                        }
                    </div>
                </div>

            </td>}
        </tr >
    );
}

export default AdReportListRow;
