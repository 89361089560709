import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import "./NotificationSetup.css";

function NotificationSetup({ onSuccess, togglePopup }) {
    const [lang, setLang] = useState([]);
    const [langSelected, setLangSelected] = useState("");
    const [notificationMessageIdSelected, setNotificationMessageIdSelected] = useState("");
    const [notification, setNotification] = useState({});
    const [notificationSousType, setNotificationSousType] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState([]);

    const updateNotificationMessageModified = useCallback(function (content) {
        setNotification(prev => ({
            ...prev,
            notification_message_modified: content
        }));
        setNotificationMessage(content);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getLang");
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setLang(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getNotificationSousType?lang_id=" + langSelected);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setNotificationSousType(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [langSelected]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_getNotificationMessage?message_id=" + notificationMessageIdSelected);
                const obj = await resp.data;
                if (obj.status && obj.results) {
                    setNotificationMessage(obj.results);
                    updateNotificationMessageModified(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [notificationMessageIdSelected, updateNotificationMessageModified]);

    const updateNotificationType = function (content) {
        let a = { ...notification };
        a.notification_type = content;

        if (content === "site") {
            document.getElementById("notificationUserId").disabled = false;
            document.getElementById("notificationUserId").value = "";
            document.getElementById("all_user_plateform").checked = false;

            a.notification_user_id = "";
        } else {
            document.getElementById("notificationSiteId").disabled = false;
            document.getElementById("notificationSiteId").value = "";
            document.getElementById("all_site_plateform").checked = false;

            a.notification_site_id = "";
        }

        setNotification(a);
    }

    const updateNotificationSiteId = function (content) {
        let a = { ...notification };
        a.notification_site_id = content;
        setNotification(a);
    }

    const updateNotificationUserId = function (content) {
        let a = { ...notification };
        a.notification_user_id = content;
        setNotification(a);
    }

    const updateNotificationLang = function (content) {
        let a = { ...notification };
        a.notification_lang_id = content;
        setNotification(a);

        setLangSelected(content);
    }

    const updateNotificationMessageId = function (content) {
        let a = { ...notification };
        a.notification_message_id = content;
        setNotification(a);

        setNotificationMessageIdSelected(content);
    }

    const checkAllSitePlaform = function (content) {
        if (content) {
            document.getElementById("notificationSiteId").disabled = true;
            document.getElementById("notificationSiteId").value = "";
        } else {
            document.getElementById("notificationSiteId").disabled = false;
        }

        updateNotificationSiteId("");
    }

    const checkAllUserPlaform = function (content) {
        if (content) {
            document.getElementById("notificationUserId").disabled = true;
            document.getElementById("notificationUserId").value = "";
        } else {
            document.getElementById("notificationUserId").disabled = false;
        }

        updateNotificationUserId("");
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const resp = await axios.post(ROOT_DOMAIN_API + "/manage/v1/bo_moderator/bo_insertNotification", {
                'notification': notification,
            });
            const obj = await resp.data;

            if (obj.status) {
                onSuccess();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="NotificationSetup FlexCol P25">
            <div className="TCenter Width100 FWBold MB10">Send Notification</div>
            <form onSubmit={handleSubmit}>
                <div className="form-section">
                    <div className="form-group">
                        <label className="form-label">Notification Type</label>
                        <div className="radio-group">
                            <div className="FlexRow">
                                <input type="radio"
                                    name="notificationType"
                                    id="notificationTypeSite"
                                    className="ActionInput"
                                    value="site"
                                    required
                                    onChange={(evt) => updateNotificationType(evt.target.value)} />
                                <label htmlFor="notificationTypeSite">Site</label>
                            </div>

                            <div className="FlexRow">
                                <input type="radio"
                                    name="notificationType"
                                    id="notificationTypeUser"
                                    className="ActionInput"
                                    value="user"
                                    onChange={(evt) => updateNotificationType(evt.target.value)} />
                                <label htmlFor="notificationTypeUser">User</label>
                            </div>
                        </div>
                    </div>

                    <div className={"MB10 " + (notification.notification_type !== "site" ? "Hide" : "")}>
                        <div className="FlexCol">
                            <span className={""}>Site ID</span>
                            <input type="text" name={"notificationSiteId"} id={"notificationSiteId"}
                                className={"ActionInput P5"}
                                placeholder={"Ex: 108,378,2374"}
                                onChange={(evt) => updateNotificationSiteId(evt.target.value)} />
                        </div>
                        <div className="FlexRow">
                            <input type="checkbox" id={"all_site_plateform"}
                                onChange={(evt) => checkAllSitePlaform(evt.target.checked)} />
                            <label for={"all_site_plateform"}>Send to all sites from the selected language</label>
                        </div>
                    </div>

                    <div className={"MB10 " + (notification.notification_type !== "user" ? "Hide" : "")}>
                        <div className="FlexCol">
                            <span className={""}>User ID</span>
                            <input type="text" name={"notificationUserId"} id={"notificationUserId"}
                                className={"ActionInput P5"}
                                placeholder={"Ex: 108,378,2374"}
                                onChange={(evt) => updateNotificationUserId(evt.target.value)} />
                        </div>
                        <div>
                            <span className={""}></span>
                            <input type="checkbox" id={"all_user_plateform"}
                                onChange={(evt) => checkAllUserPlaform(evt.target.checked)} />
                            <label htmlFor={"all_user_plateform"}>Send to all users from the selected language</label>
                        </div>
                    </div>

                    <div className={"MB10 FlexCol"}>
                        <span className={""}>Language</span>
                        <select className={"ActionInput P5"} name="notification_lang_id" id="notification_lang_id"
                            required onChange={(evt) => updateNotificationLang(evt.target.value)}>
                            <option disabled selected value={""}>Choose a language</option>
                            {lang.map((row, key) => {
                                return <option value={row.lang_id}>{row.lang_name_header}</option>
                            })}
                        </select>
                    </div>

                    <div className={"MB10" + (!notification.notification_lang_id || notification.notification_lang_id === "" ? " Hide" : "")}>
                        <span className={""}>Message Type</span>
                        <select className={"ActionInput P5"} name="notification_message_id" id="notification_message_id"
                            required onChange={(evt) => updateNotificationMessageId(evt.target.value)}>
                            <option disabled selected value={""}>Choose a message type</option>
                            {notificationSousType.map((row, key) => {
                                return <option
                                    value={row.notification_message_id}
                                    disabled={row.notification_moneybox > 0 ? false : true}
                                >
                                    [{row.notification_type}] {row.notification_sous_type}
                                </option>
                            })}
                        </select>
                    </div>

                    <div className={"MB10 FlexCol" + (!notification.notification_message_id || notification.notification_message_id === "" ? " Hide" : "")}>
                        <span className={""}>Message</span>
                        <textarea className={"ActionInput P5"}
                            id={"notification_message_area"}
                            name={"notification_message_area"}
                            value={notificationMessage}
                            rows="10"
                            onChange={(evt) => updateNotificationMessageModified(evt.target.value)}></textarea>
                    </div>
                </div>

                <div className="popup-footer Gap10 FlexRow JustifyBetween">
                    <button type="submit" className="ActionButton BGreen">
                        Send Notification
                    </button>
                    <button type="button" className="ActionButton BBlack TWhite" onClick={togglePopup}>
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
}

export default NotificationSetup;