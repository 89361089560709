import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../../Constant";
import "./NotificationList.css";
import NotificationRow from "../NotificationRow/NotificationRow";
import NotificationSetup from "../NotificationSetup/NotificationSetup";
import ReactPaginate from "react-paginate";

function NotificationList(props) {
    const [notificationList, setNotificationList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [debouncedSearchText, setDebouncedSearchText] = useState("");
    const limit = 50;

    const offset = currentPage * limit;

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const loadNotifications = useCallback(async () => {
        try {
            const payload = {
                params: {
                    offset: offset,
                    limit: limit,
                    search: debouncedSearchText
                }
            };
            const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_moderator/bo_getNotificationHistory`, payload);
            const obj = await resp.data;
            if (obj.status && obj.results) {
                setNotificationList(obj.results.payload);
                setPageCount(Math.ceil(obj.results.amount / limit));
            }
        } catch (error) {
            console.error(error);
        }
    }, [offset, debouncedSearchText]);

    const handleSuccess = () => {
        loadNotifications();
        togglePopup();
    };

    useEffect(() => {
        loadNotifications();
    }, [loadNotifications]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(0);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchText]);

    return (
        <>
            <div className={"NotificationList GridCol1-13 ShadowContainer P25 BWhite Width100 BoxSizeBorder " + props.childClass}>
                <div className="MB20">
                    <input
                        type="text"
                        className="ActionInput P10"
                        placeholder="Search notifications..."
                        value={searchText}
                        onChange={handleSearch}
                    />
                    <div className="TRight" style={{ display: 'inline-block', float: 'right' }}>
                        <button className="BGreen TWhite ActionButton ML5 NoUnderline" onClick={togglePopup}>
                            <i className="fa-solid fa-plus"></i> Send Notification
                        </button>
                    </div>
                </div>
                <table className="Width100 TCenter">
                    <thead>
                        <tr className="BRed TWhite">
                            <th className="P5" style={{ width: '10%' }}>Number of notification</th>
                            <th className="P5" style={{ width: '60%' }}>Notification message</th>
                            <th className="P5" style={{ width: '10%' }}>Sender</th>
                            <th className="P5" style={{ width: '15%' }}>Date</th>
                            <th className="P5" style={{ width: '5%' }}>No email ?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificationList.map((row, key) => (
                            <NotificationRow key={key} {...row} />
                        ))}
                    </tbody>
                </table>
                <ReactPaginate
                    className="Pagination"
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </div>
            {isPopupOpen && (
                <div>
                    <div className='overlay'></div>
                    <div className="popup">
                        <div className='popup-content-wrapper'>
                            <NotificationSetup
                                onSuccess={handleSuccess}
                                togglePopup={togglePopup}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default NotificationList; 