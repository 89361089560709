import axios from "axios";
import "./CAChart.css";
import { useEffect, useState, useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from "../../Constant";
import { BarLoader } from "react-spinners";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function CAChart(props) {
    const [graphData, setGraphData] = useState(null);
    const [averageCA, setAverageCA] = useState(0);
    const [CA, setCA] = useState(0);
    const [averageCALastYear, setAverageCALastYear] = useState(0);
    const [CALastYear, setCALastYear] = useState(0);

    const colorsList = useMemo(() => [
        "#3498db", "#e74c3c", "#e67e22", "#8e44ad", "#1abc9c", "#2ecc71", "#34495e"
    ], []);

    useEffect(() => {
        const fetchData = async () => {
            setGraphData(null);
            setAverageCA(0);
            setAverageCALastYear(0);
            try {
                const resp = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_caStatsByDate`, {
                    params: {
                        date_from: props.sDate,
                        date_to: props.eDate,
                    }
                });
                const obj = resp.data;
                if (obj.status && obj.results) {
                    let average = 0;
                    let averageLastYear = 0;
                    let nbofdays = 0;
                    let nbofdaysLastYear = 0;
                    let datasets = [];
                    let labels = [];
                    let tempColors = [...colorsList];
                    let currentYearLength = 0;

                    for (const [key, row] of Object.entries(obj.results)) {
                        if (key === String(new Date().getFullYear())) {
                            currentYearLength = row.length;
                            break;
                        }
                    }

                    for (const [key, row] of Object.entries(obj.results)) {
                        if (key === "2022") {
                            row.forEach(r => {
                                labels.push(r.pf_date.substring(5));
                            });
                        }
                        if (key === String(new Date().getFullYear())) {
                            average = row.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.revenues), 0);
                            nbofdays = row.length;
                        }
                        if (key === String(new Date().getFullYear() - 1)) {
                            // We use slice(0, currentYearLength) to take exactly the same number of days as year N
                            const dataAlignedWithCurrentYear = row.slice(0, currentYearLength);
                            averageLastYear = dataAlignedWithCurrentYear.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.revenues), 0);
                            nbofdaysLastYear = dataAlignedWithCurrentYear.length;
                        }

                        let perfData = [];
                        perfData = row.map(r => r.revenues);

                        let color = tempColors.shift();
                        datasets.push({
                            label: key,
                            lineTension: 0.3,
                            data: perfData,
                            borderColor: color,
                            backgroundColor: color,
                            yAxisID: 'y',
                        });
                    }

                    if(nbofdays !== parseInt(0)){
                        setAverageCA((parseInt(average / nbofdays)).toLocaleString());
                    }
                    if(nbofdaysLastYear !== parseInt(0)){
                        setAverageCALastYear((parseInt(averageLastYear / nbofdaysLastYear)).toLocaleString());
                    }

                    setCA(average.toLocaleString());
                    setCALastYear(averageLastYear.toLocaleString());
                    setGraphData({
                        labels,
                        datasets
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [props.sDate, props.eDate, colorsList]);

    return (
        <div className={"CAChart GridCol1-13 ShadowContainer BWhite P15"}>
            <div className="FlexRow">
                <div className="FlexCol" id="CAOfTheMonth">
                    <h2 className="M0 MT5 MB5">
                        CA of the month : {CA} € 
                        <span className="SmallText Gray ML10">({CALastYear} € N-1)</span>
                    </h2>
                    <h3>
                        Average this month : {averageCA} €
                        <span className="SmallText Gray ML10">({averageCALastYear} € N-1)</span>
                    </h3>
                </div>
            </div>
            <div className="InnerCAChart">
                {graphData ? <Line data={graphData} height={300}
                                   options={{
                                       maintainAspectRatio: false, responsive: true, scales: {
                                           y: {
                                               min: 0
                                           }
                                       }
                                   }}/> : <BarLoader height={8} color="#f41127"/>}
            </div>
        </div>
    );
}

export default CAChart;
